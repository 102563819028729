<template>
    <div class="p-2">
        
        <div class="">
            <router-link to="/bussiness/training" class="h4 mb-0 text-white d-flex align-items-center" style="">
                <i class="bx bx-chevron-left" style="font-size: 20px;color: #FFAD32;"></i>
                Back
            </router-link>
        </div>

         <!-- NAV HEADER -->
         <!-- END NAV -->
         <div class="d-flex mt-2">
            <ul class="m-auto nav nav-pills">
                <li class="nav-item" v-if="id">
                    <router-link class="nav-link font-weight-bold" 
                        style="color:#1B628C;background-color: white;border-radius: 10px 0 0 10px;font-size: 16px;" 
                        :to="`/bussiness/training/form/${id}`"
                    >
                    Form Details</router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link font-weight-bold" 
                        style="border-radius: 0 10px 10px 0;background-color: #1EB4F1;color:white;font-size: 16px;"
                        :to="`/bussiness/training/form/${id}/schedules`">Schedules</router-link>
                </li>
            </ul>
        </div>

        <div class="h-100 flex-fill d-flex flex-column">
            <h3 class="text-center font-weight-bolder text-white mt-2">Training Schedules</h3>

            <div class="mt-1 mb-2">

                <div class="d-flex align-items-center mb-1" style="gap:10px">
                    <input class="flex-fill form-control" v-model="trs_date" type="date" style="background: transparent;color: white;border:2px solid #E3A230;" />
                    <input class="flex-fill form-control" v-model="trs_time" type="time" style="background: transparent;color: white;border:2px solid #E3A230;" />
                </div> 

                <div class="mt-1 d-flex">
                    <div class="text-white btn mx-auto" @click="onSubmit()"
                        style="padding:.5em 3em;gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;">
                        <div class="h4 text-white mb-0">Submit</div>
                    </div>
                </div>

            </div>


            <div v-for="(item,key) in schedules">
                <h4 class="text-white mb-1 mt-2">Schedule {{ moment(key).format('dddd, DD MMMM YYYY') }}</h4>
                <div v-for="time in item">
                    <div class="text-white d-flex align-items-center p-1 mt-1" style="gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;">
                        <div class="h4 text-white mb-0">{{ time.trs_time }}</div>
                        <div class="h4 text-white mb-0 ml-auto" @click="deleteSchedule(time.trs_id)"><i class="bx bx-trash"></i></div>
                    </div>
                </div>
            </div>      
        </div>
    </div>
</template>

<script>

import store from '@/store'
import Cookies from 'universal-cookie'
import moment from 'moment'

let cookies = new Cookies()

export default {
    computed: {
        training(){
            return store.state.training.TRAINING
        },
        schedules(){
            return this.training?.schedules_ || []
        },
        user(){
            return store.state.auth.USER
        }
    },  
    watch: {
        training(val){
            if(val.user_id != this.user.user_id){
                this.$router.push('/homepage')
            }
        }
    },
    methods: {
        onSubmit(){
            let formData = new FormData()
            formData.append('inp[trs_date]', this.trs_date)
            formData.append('inp[trs_time]', this.trs_time)
            formData.append('inp[tr_id]', this.id)
            store.dispatch('training/SaveSchedule', formData).then(() => {
                store.dispatch('training/GetTraining', this.id)
                this.trs_date = ''
                this.trs_time = ''
            })
        },
        deleteSchedule(id){
            store.dispatch('training/DeleteSchedule', id).then(() => {
                store.dispatch('training/GetTraining', this.id)
            })
        }
    },
    mounted(){
        if(this.id){
            store.dispatch('training/GetTraining', this.id)
        }
    },
    data(){
        return {
            id: this.$route.params.id, 
            trs_date: '',
            trs_time: '',
            moment
        }
    }
}

</script>

<style>
.auth-page .btn {
    border-radius: 20px;
}

input:focus {
    color: white !important;
}

.input-group span {
    border: unset;
    border-radius: unset;
    border-bottom: 1px solid white;
    font-size: 24px;
    color: #1F9BCD;
    background-color: transparent;
}

.input-group input, .input-group textarea {
    margin-left: 0;
    padding-left: 0;
    border: unset;
    border-radius: unset;
    border-bottom: 1px solid white;
    height: 50px;
    color: white;
    background-color: transparent !important;
}
</style>